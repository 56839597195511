interface Params<T> {
  domRef: Ref<HTMLElement | undefined>
  data: Ref<T[]>
  itemsPerPage: number
}

export const useInfiniteScroll = <T>({ data, domRef, itemsPerPage }: Params<T>) => {
  const observer = ref<IntersectionObserver>()
  const page = ref(1)

  const items = ref<T[]>(data.value.slice(0, itemsPerPage)) as Ref<T[]>

  watch(
    () => data.value.length,
    () => {
      items.value = data.value.slice(0, page.value * itemsPerPage)
    }
  )

  const handleIntersect = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      page.value++
      items.value = data.value.slice(0, page.value * itemsPerPage)
    }
  }

  onMounted(() => {
    observer.value = new IntersectionObserver(
      entries => {
        handleIntersect(entries[0])
      },
      {
        root: null, // args
        threshold: 0, // args,
      }
    )

    if (domRef.value) observer.value.observe(domRef.value)

    onUnmounted(() => {
      if (observer) observer?.value?.disconnect()
    })
  })

  return items
}
